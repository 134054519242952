:root {
  font-family: 'Work Sans', -apple-system, BlinkMacSystemFont, 'Helvetica Neue',
    Roboto, sans-serif;
  letter-spacing: -0.006em;
}

/* work-sans-regular - latin-ext_latin */
@font-face {
  font-family: 'Work Sans';
  font-style: normal;
  font-weight: 400; // default
  src: url('../../fonts/WorkSans-Regular.woff2') format('woff2'),
    url('../../fonts/WorkSans-Regular.woff') format('woff'),
    url('../../fonts/WorkSans-Regular.ttf') format('truetype');
}

/* work-sans-600 - latin-ext_latin */
@font-face {
  font-family: 'Work Sans';
  font-style: normal;
  font-weight: 600;
  src: url('../../fonts/WorkSans-Semibold.woff2') format('woff2'),
    url('../../fonts/WorkSans-Semibold.woff') format('woff'),
    url('../../fonts/WorkSans-Semibold.ttf') format('truetype');
}

/* work-sans-500 - latin-ext_latin */
@font-face {
  font-family: 'Work Sans';
  font-style: normal;
  font-weight: 500;
  src: url('../../fonts/WorkSans-Medium.woff2') format('woff2'),
    url('../../fonts/WorkSans-Medium.woff') format('woff'),
    url('../../fonts/WorkSans-Medium.ttf') format('truetype');
}

/* work-sans-700 - latin-ext_latin */
@font-face {
  font-family: 'Work Sans';
  font-style: normal;
  font-weight: 700;
  src: url('../../fonts/WorkSans-Bold.woff2') format('woff2'),
    url('../../fonts/WorkSans-Bold.woff') format('woff'),
    url('../../fonts/WorkSans-Bold.ttf') format('truetype');
}
