.amh-accounts-input {
  border: 1px solid var(--border);
  color: var(--font-color);
  font-size: calc(14rem / 16);
  line-height: calc(1em / 14 * 22);
  padding: calc(1em / 14 * 21) calc(1em / 14 * 10) calc(1em / 14 * 7);
  position: relative;
  width: 100%;
  z-index: 10;

  &::placeholder {
    color: var(--placeholder);
  }

  &:focus {
    border-color: var(--focus);
    outline: none;
  }

  /* stylelint-disable selector-class-pattern */

  &.ng-touched.ng-invalid:not(:focus),
  &.ng-dirty.ng-invalid:not(:focus) {
    border-color: var(--error);
  }

  /* stylelint-enable selector-class-pattern */
}
