.amh-accounts-link {
  text-decoration: none;

  &:link,
  &:visited,
  &:hover,
  &:active {
    color: var(--link-color);
  }

  &:hover {
    text-decoration: underline;
  }
}
