:root {
  --aqua: #31b7bc;
  --aqua-900: #47bfc3;
  --aqua-800: #5ac5c9;
  --aqua-700: #6eccd0;
  --aqua-600: #83d4d7;
  --aqua-500: #98dbdd;
  --aqua-400: #ade2e4;
  --aqua-300: #c1e9ea;
  --aqua-200: #d6f1f2;
  --aqua-100: #eaf7f8;
  --black: #000;
  --grey-900: #333;
  --grey-800: #666;
  --grey-700: #999;
  --grey-600: #acacac;
  --grey-500: #ccc;
  --grey-400: #ededed;
  --grey-300: #f6f6f6;
  --grey-200: #fcfcfc;
  --white: #fff;
  --light-blue-grey-100: #edeff1;
  --light-blue-grey-200: #e5e5ea;
  --warm-grey: #87867e;
  --petrol: #003538;
  --petrol-light: #19494c;
  --pale-blue: #cadce0;
  --sky: #4f7be1;
  --lime: #ffeb80;
  --lime-light: #fff3b4;
  --mustard: #a38500;
  --signal-green: #50b83c;
  --signal-green-dark: #409330;
  --signal-green-light: #ddf1d9;
  --signal-red: #e30000;
  --signal-red-dark: #b50000;
  --signal-red-light: #fad6d6;
  --signal-blue: #007ace;
  --signal-blue-dark: #0061a4;
  --signal-blue-light: #cce5f6;
  --signal-yellow: #eec200;
  --signal-yellow-dark: #be9b00;
  --signal-yellow-light: #fbf2cc;
}

/* stylelint-disable no-duplicate-selectors */
:root {
  --border: var(--light-blue-grey-200);
  --disabled: #ebebeb;
  --disabled-font-color: var(--warm-grey);
  --error: var(--signal-red);
  --focus: var(--aqua-800);
  --font-color: #222;
  --font-color-inverted: var(--white);
  --font-color-reduced: var(--warm-grey);
  --link-color: var(--aqua);
  --placeholder: var(--warm-grey);
  --primary: var(--aqua);
  --primary-hover: var(--aqua-600);
}
