%amh-accounts-button {
  background-color: var(--primary);
  border: 1px solid var(--primary);
  color: var(--font-color-inverted);
  display: inline-block;
  font-size: 1rem; // 16px
  letter-spacing: inherit;
  padding: calc(1em / 16 * 13) calc(1em / 16 * 25) calc(1em / 16 * 14);
  text-decoration: none;

  @media (min-width: calc(1rem / 16 * 768)) {
    font-size: calc(1rem / 16 * 14);
    padding: calc(1em / 14 * 10) calc(1em / 14 * 25);
  }
}

.amh-accounts-button {
  @extend %amh-accounts-button;

  &:visited {
    @extend %amh-accounts-button;
  }

  &:hover,
  &:active {
    background-color: var(--primary-hover);
    border-color: var(--primary-hover);
    color: var(--font-color-inverted);
  }

  &[disabled] {
    background-color: var(--disabled);
    border-color: var(--disabled);
    color: var(--disabled-font-color);
  }
}

.amh-accounts-button--wide {
  text-align: center;
  width: 100%;
}
